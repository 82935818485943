<template>
  <div id="wrapper">
    <div
      id="sidebar-1"
      no-header
      class="sidebar"
    >
      <template>
        <div id="header-sidebar">
          <b-row>
            <b-col md="3">
              <img
                src="/assets/images/logo-washliyah-white.png"
                alt=""
                width="50"
              />
            </b-col>
            <b-col md="9" class="pt-1">
              <p class="app-title">PUSAT DATA KEANGGOTAAN</p>
              <p class="app-content">AL JAM’IYATUL WASHLIYAH SUMATERA UTARA</p>
            </b-col>
          </b-row>
        </div>
      </template>
      <nav class="mt-3">
        <b-row class="justify-content-center mt-3 mb-3">
          <b-col md="9" class="text-center">
            <img src="/assets/images/account.png" alt="" width="100" />

            <template v-if="$store.getters['account/accountData'].role == 'admin'">
              <p class="account-name" style="margin: 4px 0;">{{ $store.getters['account/accountData']? $store.getters['account/accountData'].name : '' }}</p>
              <p class="account-name">Pengurus Daerah</p>
              <p class="text-white" style="font-weight: 700; margin-bottom: 0;">Al Jam’iyatul Washliyah</p>
              <p class="text-white" style="font-weight: 700; margin-bottom: 0;">{{ $store.getters['account/accountData'] && $store.getters['account/accountData'].regency ? $store.getters['account/accountData'].regency.name : '' }}</p>
            </template>

            <template v-else>
              <p class="account-name" style="margin: 4px 0;">{{ $store.getters['account/accountData']? $store.getters['account/accountData'].name : '' }}</p>
              <p class="account-name">Pengurus Wilayah</p>
              <p class="text-white" style="font-weight: 700; margin-bottom: 0;">Al Jam’iyatul Washliyah</p>
              <p class="text-white" style="font-weight: 700; margin-bottom: 0;">Provinsi Sumatera Utara</p>
            </template>

          </b-col>
        </b-row>
        <b-nav
          vertical
          type="light"
          variant="light"
          class="justify-content-center"
        >
          <b-nav-item :to="'/dashboard'"><span><font-awesome-icon icon="home" class="icon" /></span>Beranda</b-nav-item>
          <b-nav-item :to="'/users'" v-if="$store.getters['account/accountData'] && $store.getters['account/accountData'].role == 'super admin'"><span><font-awesome-icon icon="user" class="icon" /></span> Kelola User</b-nav-item>
          <b-nav-item :to="'/members'"><span><font-awesome-icon icon="id-card" class="icon" /></span> Data Anggota</b-nav-item>
          <b-nav-item :to="'/members-waiting'"><span><font-awesome-icon icon="folder-open" class="icon" /></span>Daftar Tunggu</b-nav-item>
          <b-nav-item :to="'/members-create'"><span><font-awesome-icon icon="user-plus" class="icon" /></span>Tambah Anggota</b-nav-item>
          <b-nav-item :to="'/signatures'" v-if="$store.getters['account/accountData'] && $store.getters['account/accountData'].role == 'super admin'"><span><font-awesome-icon icon="file-signature" class="icon" /></span>Tanda Tangan</b-nav-item>
          <b-nav-item href="https://member.maribuatweb.com/buku%20induk%20anggota.pdf" target="_blank" v-if="$store.getters['account/accountData'].role === 'super admin'"><span><font-awesome-icon icon="book" class="icon" /></span>Download Buku Induk</b-nav-item>
        </b-nav>
      </nav>
    </div>
    <div id="content-wrapper" class="d-flex flex-column">
      <b-navbar
        id="large-nav"
        class="hidden-sm-down"
        toggleable="lg"
        type="light"
        variant="light"
        fixed
      >
        <b-navbar-brand v-b-toggle.sidebar-1>
          <font-awesome-icon icon="bars" class="icon d-none" />
          <h3>{{ $route.name }}</h3>
        </b-navbar-brand>
        <b-dropdown id="nav-dropdown" right text="Akun" variant="transparent">
          <b-dropdown-item href="#/settings/password"
            >Ubah Password</b-dropdown-item
          >
          <div class="dropdown-divider"></div>
          <b-dropdown-item @click.prevent="logOut">Sign Out</b-dropdown-item>
        </b-dropdown>
      </b-navbar>

      <b-container fluid class="mt-3 mb-3">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </b-container>
    </div>
    <!-- <notifications/> -->
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Content from './Content'

export default {
  name: 'general-screen-dashboard',
  components: {
    Navbar,
    Content
  },
  methods: {
    logOut() {
      this.$swal({
        title: "Anda Yakin Akan Log Out?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        confirmButtonClass: "btn btn-sm btn-danger mr-3",
        cancelButtonClass: "btn btn-sm btn-secondary",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("account/logout")
            .then(() => {
              this.$router.push("/auth/login");
              // location.reload()
            })
            .catch(() => {
              this.$router.push("/auth/login");
              // location.reload()
            });
        }
      });
    },
  }
}
</script>
